export const me = {
  body: {
    title: "Toronto-based interaction designer, JS & React developer, and music enthusiast.",
    about_p1: "Hi, I'm Aaron, a Toronto-based (sort of) experience designer and developer with the goal of integrating music into my work. For me, the technical side of creating music, be it the softwares, the tools, etc., is a very open space to explore. I want to have a part in evolving the way we can create and experience music.",
    about_p2: "I generally prefer to create one off or otherwise unique interfaces that feel new and refreshing, rather than building off of standardized styles and layouts. Although I am still very capable of the latter, creating something special for each situation has more appeal to me than sticking to guidelines (which is probably a sentiment that's not too uncommon).",
    about_p3: "If my work reflects what you're looking for, then feel free to contact me!"
  },
  cv: {
    education: [
      {
        title: "Bachelors of Experience Design (2022)",
        sub: "George Brown College • Honours • ",
        status: "Completed"
      },

      {
        title: "Interaction Design & Development Advanced Diploma (2019)",
        sub: "George Brown College • Honours • ",
        status: "Completed"
      }
    ],
    work: [
      {
        title: "AudioWorks (2023)",
        company: "Suture Sound Inc. • ",
        status: "Ongoing",
        role: "Full-stack Developer, UI/UX Designer"
      },
      {
        title: "Flip Sampler (2022)",
        company: "Suture Sound Inc. • ",
        role: "UI/UX Designer"
      },
      {
        title: "Institute without Boundaries (2019 & 2022)",
        company: "Institute Without Boundaries • ",
        role: "Front-end Developer, UI/UX Designer, Illustrator"
      },
    ],
    awards: [
      {
        title: "Dr. Elise Hodson Research & Design Excellence Award (2019)",
        sub: "George Brown College"
      },

      {
        title: "Highest Academic Achievement (2017 & 2022)",
        sub: "George Brown College"
      }
    ]
  },
  contact: {
    email: "aaron@deroche.design",
    phone: "905-741-9335"
  },
  socials: {
    links: ["https://instagram.com/aaronderoche", "https://github.com/derochedesign", "https://www.linkedin.com/in/aaronderoche/"],
    projects: [
      {
        link: "https://aarkeband.netlify.app",
        title: "aarke.band"
      }
    ]
  }
}