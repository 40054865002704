import React, { useEffect, useState, useRef, useHistory } from "react";
import { motion } from "framer-motion";
import Footer from "components/Footer";
import Topbar from "components/Topbar";
import ScrollMemory from "components/ScrollMemory";
import {me as meData} from "data/all";

import insta from "img/icons/social/instagram.svg";
import github from "img/icons/social/github.svg";

import meimg from "img/me.jpg";
import cvDl from "img/cv-deroche.pdf";

const Me = (props) => {

  const [expands, setExpands] = useState([false, false, false]);
  const expandList = useRef(null);

  const item = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 0.5, ease: "easeInOut" } }
  }

  const slowerAnim = {
    visible: { opacity: 1, y: 0, transition: { duration: 1.5, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 1.5, ease: "easeInOut" } }
  }

  const handleExpand = (id => {

    const size = expandList.current.children[id].children[1].children[0].offsetHeight;

    if (expands[id]) {
      expandList.current.children[id].children[1].style.maxHeight = 0 + "px";
    }
    else {
      expandList.current.children[id].children[1].style.maxHeight = size + "px";
    }

    let temp = expands;
    temp[id] = !temp[id];

    setExpands([...temp]);

  });

  return (
    <>
      <ScrollMemory y={0} x={0} />
      <div className="grain"></div>
      <Topbar state={"me"} appDiv={props.appDiv} />
      <motion.main className="tile me invert" initial="hidden" animate="visible">
        <div className="shapes">
          <div className="square-shape"></div>
          <div className="circle-shape"></div>
        </div>
        <motion.section className="layout" variants={item}>
          <div className="hero-me">
            <div className="me-img">
              <img src={meimg} alt="it me"></img>
            </div>
            <div className="hero-text">
              <motion.h1 variants={slowerAnim} className="coloured">{meData.body.title}</motion.h1>
            </div>
          </div>
          <motion.h2 variants={slowerAnim}>About Me</motion.h2>
          <motion.p variants={slowerAnim}>{meData.body.about_p1}</motion.p>
          <motion.p variants={slowerAnim}>{meData.body.about_p2}</motion.p>
          <motion.p variants={slowerAnim}>{meData.body.about_p3}</motion.p>
          <motion.ul variants={slowerAnim} ref={expandList} className="expand-list">
            <li className="expandable">
              <div className="expand-info" onClick={(() => handleExpand(0))}>
                <h6>01</h6>
                <h2>{(expands[0]) ? String.fromCharCode(8722) : "+"} Experience <span>(CV)</span></h2>
                <h4>Education, work experience, etc.</h4>
              </div>
              <div className={(expands[0]) ? "expand-body active" : "expand-body"}>
                <div className="inner-expand-body">
                  <div className="header"><div></div><h3>Education</h3></div>
                  {
                    meData.cv.education.map((entry, i) =>
                      <article key={i}>
                        <p>{entry.title}</p>
                        <h4>{entry.sub}<span>{entry.status}</span></h4>
                      </article>
                    )
                  }
                  <div className="header"><div></div><h3>Work Experience</h3></div>
                  {
                    meData.cv.work.map((entry, i) =>
                      <article key={i}>
                        <p>{entry.title}</p>
                        <h4>{entry.sub}<span>{entry.status}</span></h4>
                        <h4>{entry.role}</h4>
                      </article>
                    )
                  }

                  <div className="header"><div></div><h3>Accolades</h3></div>
                  {
                    meData.cv.awards.map((entry, i) =>
                      <article key={i}>
                        <p>{entry.title}</p>
                        <h4>{entry.sub}</h4>
                      </article>
                    )
                  }
                  <section>
                    <button type="button" className="text"><a href={cvDl} download>Full CV (PDF)</a></button>
                  </section>
                </div>

              </div>
            </li>
            <li className="expandable">
              <div className="expand-info" onClick={(() => handleExpand(1))}>
                <h6>02</h6>
                <h2>{(expands[1]) ? String.fromCharCode(8722) : "+"} Contact</h2>
                <h4>Message me via email or text.</h4>
              </div>
              <div className={(expands[1]) ? "expand-body active" : "expand-body"}>
                <div className="inner-expand-body">
                  <div className="header"><div></div><h3>Email</h3></div>
                  <section><a href="mailto:aaron@deroche.design" target="_top"><p>{meData.contact.email}</p></a></section>
                  <div className="header"><div></div><h3>Text</h3></div>
                  <p>{meData.contact.phone}</p>
                </div>

              </div>
            </li>
            <li className="expandable">
              <div className="expand-info" onClick={(() => handleExpand(2))}>
                <h6>03</h6>
                <h2>{(expands[2]) ? String.fromCharCode(8722) : "+"} Socials</h2>
                <h4>Follow me on instagram, or check out my music.</h4>
              </div>
              <div className={(expands[2]) ? "expand-body active" : "expand-body"}>
                <div className="inner-expand-body">
                  <section className="social-icons">
                    <a href={meData.socials.links[0]} target="_blank" rel="noopener"><img src={insta}></img></a>
                    <a href={meData.socials.links[1]} target="_blank" rel="noopener"><img src={github}></img></a>
                  </section>
                  <div className="header"><div></div><h3>My Projects</h3></div>
                  {
                    meData.socials.projects.map((pro, i) =>
                      <section key={i}><a href={pro.link} target="_blank" rel="noopener"><p>{pro.title}</p></a></section>
                    )
                  }
                </div>
              </div>
            </li>
          </motion.ul>
        </motion.section>
      </motion.main>
      <Footer />
    </>
  )
}

export default Me;