import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";

const Goals = props => {
    
    let item = {
        visible: {opacity:1,y:0, transition:{ duration:1,ease: "easeInOut"}},
        hidden: {opacity:0,y:30, transition:{ duration:1,ease: "easeInOut"}}
    }
    
    console.log(props);
    
    return (
        <section className="goals">
            <div className="info">
                <h1>Goals</h1>
                {props.spaceText(props.work.focus.goals.content)}
            </div>
            <div className="images">
                <div className="photograph-overlap">
                    <motion.div className="photograph" style={{backgroundImage: `url(${require(`img/projects/def/${props.work.slug}/${props.work.focus.goals.img[0]}`)})`}}>
                    </motion.div>
                    <motion.div className="photograph-sub" style={{backgroundImage: `url(${require(`img/projects/def/${props.work.slug}/${props.work.focus.goals.img[1]}`)})`}}>
                    </motion.div>
                </div>
            </div>
        </section>
    )
}

export default Goals;