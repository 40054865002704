import React, {useState, useRef, useEffect} from "react";
import { Switch, Route, Link, BrowserRouter as Router} from "react-router-dom";
import Main from 'components/Main';
import Focus from 'components/Focus';
import works from 'data/works';
import Me from "components/Me";

const App = () => {
  
  const viewportH = window.innerHeight;
  const viewportW = window.innerWidth;
  const appDiv = useRef(null);
  const data = works;
  const root = document.documentElement;
  
  const [dimensions, setDimensions] = React.useState({ 
    height: viewportH,
    width: viewportW
  });
  
  console.log("app refresh");
  
  useEffect(() => {
    
    root.style.setProperty('--real-vh', viewportH + "px");
    
    function handleResize() {
      
      if (window.location.pathname == "/work" || window.location.pathname == "/") {
        setDimensions({
          height: viewportH,
          width: viewportW
        });
      }
    }
    
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
    
  });
  
  
  return (
      <Router>
        <div className="app" ref={appDiv}>
          <Switch>
            <Route exact path="/" render={(props) => <Main {...props} works={data} vH={viewportH} vW={viewportW}/>}/>
            <Route exact path="/work" render={(props) => <Main {...props} works={data} position={"work"} vH={viewportH} vW={viewportW}/>}/>
            <Route path="/me" render={(props) => <Me {...props} appDiv={appDiv}/>}/>
            <Route exact path="/:project" render={(props) => <Main {...props} works={data} position={"focus"} vH={viewportH} vW={viewportW}/>}/>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
