import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

//blocks
import Goals from "components/focus-blocks/Goals";
import Research from "components/focus-blocks/Research";
import Challenges from "components/focus-blocks/Challenges";
import Scroller from "components/focus-blocks/Scroller";
import Outcomes from "components/focus-blocks/Outcomes";
import Gallery from "components/focus-blocks/Gallery";
import Text from "components/focus-blocks/Text";
import Video from "components/focus-blocks/Video";

import closeIcon from "img/icons/close.svg";

const Focus = props => {

  const history = useHistory();
  const work = props.works.filter(work => work.slug === props.url)[0];
  const focusBody = useRef(null);
  const closeH3 = useRef(null);
  let userScrolling = false;
  let isScrolling;
  let closeIt = false;
  const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const animInnerOut = useAnimation();

  let initMousePos;

  let tagString = "";
  work.tags.map((tag, i) => {
    if (i !== work.tags.length - 1) {
      tagString = tagString.concat(`${tag} \u2219 `);
    }
    else {
      tagString = tagString.concat(`${tag}`);
    }
  });

  useEffect(_ => {
    window.scrollTo(0, 0);
    document.body.style.overscrollBehavior = "none";
    closeH3.current.style.opacity = 0;
  });

  const scrolling = _ => {
    userScrolling = true;

    // Clear our timeout throughout the scroll
    window.clearTimeout(isScrolling);

    // Set a timeout to run after scrolling ends
    isScrolling = setTimeout(function () {

      userScrolling = false;


    }, 200);
  }

  const closeFocus = _ => {
    if (!userScrolling && focusBody.current.scrollTop === 0) {
      document.addEventListener("touchmove", onDrag);
      document.addEventListener("touchend", releaseDrag);
    }
  }
  const onDrag = e => {

    const y = e.touches[0].clientY;

    if (focusBody.current.scrollTop === 0) {

      if (!initMousePos) {
        initMousePos = y;

      }
      //console.log((focusBody.current.style.transform.replace(/[^-?\d+.\d+?$]/g, '')));

      if ((y - initMousePos) >= 0 && (y - initMousePos) < 150) {

        focusBody.current.style.transform = `translateY(${y - initMousePos}px)`;
      }
      if (y - initMousePos > 10) {
        focusBody.current.style.overflow = "hidden";

      }
      if (y - initMousePos >= 145) {
        closeIt = true;
        closeH3.current.style.opacity = 1;

      }
      else {
        closeIt = false;
        closeH3.current.style.opacity = 0.3;
      }
    }

  }
  const releaseDrag = _ => {
    document.removeEventListener("touchmove", onDrag);
    document.removeEventListener("touchend", releaseDrag);
    initMousePos = false;
    focusBody.current.style.overflow = "auto";
    focusBody.current.style.transform = `translateY(0px)`;
    if (closeIt) {
      closeFocusAnim();
    }
  }
  const closeFocusAnim = _ => {
    focusBody.current.scrollTo({ top: 0, behavior: "smooth" });
    animInnerOut.start({
      opacity: 0,
      transition: { duration: 0.5, ease: "easeInOut" }
    });
  }

  const handleBack = _ => {
    props.closeFunc();
    history.goBack();
  }

  const spaceText = text => {
    return text.split('\n').map((str, i) => <p key={i}>{str}</p>);
  }

  const getComponent = key => {

    const comp = {
      goals: Goals,
      research: Research,
      challenges: Challenges,
      scroller: Scroller,
      outcomes: Outcomes,
      gallery: Gallery,
      text: Text,
      video: Video
    }

    return comp[key];
  }

  if (work === undefined) {
    //404
    history.push("/");
  }

  const item = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 0.8, ease: "easeInOut" } }
  }
  const fade = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 0, transition: { duration: 0.8, ease: "easeInOut" } }
  }

  return (
    <>
      <div className="close-focus-scroll"><h3 ref={closeH3}>close</h3></div>

      {/* buggy (for below line) onScroll={(!iOS) && scrolling} onTouchStart={(!iOS) && closeFocus}*/}
      <motion.div ref={focusBody} onScroll={(!iOS) && scrolling} onTouchStart={(!iOS) && closeFocus} initial="hidden" animate="visible" className="focus invert" style={(!iOS) && ({ height: "100vh" })}>

        {(work !== undefined) &&
          <>
            <div className="focus-hero">
              <div className="hero-image" style={{ backgroundImage: `url(${require(`img/projects/${(work.def) ? "def" : "alt"}/${work.slug}/thumb.jpg`)})` }} alt="project"></div>

              <motion.div initial={{ opacity: 0, transition: { duration: 0.8, ease: "easeInOut" } }} animate={{ opacity: 1, transition: { duration: 0.8, ease: "easeInOut" } }} className="tile focus-header invert">

                <motion.h1 variants={item}>{work.title}</motion.h1>
                <motion.button variants={item} className="icon close" type="button" onClick={closeFocusAnim}><img src={closeIcon} alt="close project"></img></motion.button>
                <motion.div variants={item} className="focus-info">

                  <ul variants={item} className="info-list">
                    <li>
                      <h4><span>For</span></h4><h4>{work.info.for}</h4>
                    </li>
                    <li>
                      <h4><span>Outcome</span></h4><h4>{work.info.outcome}</h4>
                    </li>
                    <li>
                      <h4><span>When</span></h4><h4>{work.year}</h4>
                    </li>
                    <li>
                      <h4><span>Role</span></h4><h4>{work.info.role}</h4>
                    </li>
                    <li>
                      <h4><span>Tags</span></h4><h4>{tagString}</h4>
                    </li>
                  </ul>

                  <div className="project-image-sub2" style={{ backgroundImage: `url(${require(`img/projects/def/${work.slug}/thumb3.jpg`)})` }}></div>

                  <article>
                    <h3>Overview</h3>
                    {spaceText(work.focus.desc)}
                    {work.focus.link &&
                      <div className="link-out">
                        <div className="title">
                          <h3>{work.focus.link[0].type}</h3>
                          {(work.focus.link[0].alert !== null) && 
                            <h4>({work.focus.link[0].alert})</h4>
                          }
                        </div>
                        {work.focus.link.map(l => 
                          <a key={l.url} href={l.url} target="_blank" rel="noopener">{l.title}</a>
                        )}
                      </div>
                    }
                  </article>
                </motion.div>
              </motion.div>
            </div>
            <div className="keep-scrolling">
              <div className="rotate-text">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                  <defs>
                    <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 " />
                  </defs>
                  <circle cx="150" cy="100" r="75" fill="none" />
                  <circle cx="150" cy="150" r="40" fill="#f0a76c" />
                  <g>
                    <use xlinkHref="#circlePath" fill="none" />
                    <text fill="#fff">
                      <textPath xlinkHref="#circlePath">&nbsp;&nbsp;&nbsp;&nbsp;Keep Scrolling&nbsp;&nbsp;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;&nbsp;&nbsp;Keep Scrolling&nbsp;&nbsp;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;&#8277;&nbsp;&nbsp;</textPath>

                    </text>
                  </g>
                </svg>
              </div>
              {/* <img src={arrow} alt="down arrow"/> */}
            </div>
            <motion.div initial={{ opacity: 1 }} animate={animInnerOut} onAnimationComplete={handleBack} className="inner-focus">

              <div className="focus-body">
                {
                  work.focus.components.map((block, i) => React.createElement(getComponent(block), { work: work, spaceText: spaceText, key: i, i: i }))
                }
              </div>
            </motion.div>
          </>
        }
      </motion.div>
    </>
  )
}

export default Focus;