import React from "react";
import { motion } from "framer-motion";

import logoSm from "img/logo-sm.svg";

const Footer = () => {

  let item = {
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 1, ease: "easeInOut" } }
  }

  return (
    <motion.footer className="footer tile">
      <div variants={item}>
        {/* <img src={logo} className="logo" alt="deroche logo"></img> */}
        <img src={logoSm} alt="small logo"></img>
        <h5>(2023)</h5>
      </div>
    </motion.footer>
  )
}

export default Footer;