import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import MenuGrab from "components/MenuGrab";

import logoSm from "img/logo-sm.svg";
import arrow from "img/icons/arrow.svg";

const Footer = props => {

  let item = {
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 1, ease: "easeInOut" } }
  }

  return (
    <>
      <MenuGrab mainElem={props.mainElem} focusElem={props.focusElem} appDiv={props.appDiv} />
      <nav className="topbar">
        <Link onClick={props.navListener} to="/"><img src={logoSm}></img></Link>
        <div className="pages">
          <Link onClick={props.navListener} to="/work"><h3 className={(props.state === "work") ? ("selected") : ""}>work</h3></Link>
          <Link onClick={props.navListener} to="/me"><h3 className={(props.state === "me") ? ("selected") : ""}>me</h3></Link>
        </div>
      </nav>
    </>
  )
}

export default Footer;