import React from "react";

const Video = (props) => {

  return (
    <section className="video">
      <iframe width="100%" src={props.work.focus.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </section>
  )
}

export default Video;