import React, {useRef, useEffect, useState} from "react";
import { motion } from "framer-motion";
import Menu from 'components/Menu';
import ReactDOM from 'react-dom';

import logoSm from "img/logo-sm.svg";
import arrow from "img/icons/arrow.svg";

const Footer = props => {

    //const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [menuOpen, setMenu] = useState(false);
    const menuGrab = useRef(null);
    let menuGrabDefSize;
    let initMousePos;
    let moved = false;
    
    useEffect(()=> {
        if (!menuOpen) menuGrabDefSize = {w:menuGrab.current.clientWidth, h: menuGrab.current.clientHeight};
        
    });
    
    if (menuOpen) {
        document.body.classList.add("disable");
        document.body.style.overflow = "hidden";
        //props.mainElem.current.style.filter = "blur(10em)";
        //idk how to get focus elem
    }
  
    const onMouseMove = e => {
      const x = (e.type === "mousemove") ? e.clientX : e.touches[0].clientX;
      const y = (e.type === "mousemove") ? e.clientY : e.touches[0].clientY;
      
      (!moved) && (initMousePos = { xPos: x, yPos: y });
      moved = true;
      
      let xDir = ((initMousePos.xPos - x) + menuGrabDefSize.w);
      let yDir = ((y - initMousePos.yPos) + menuGrabDefSize.h);
      let maxDir = Math.max(xDir, yDir);
      
      menuGrab.current.style.height = maxDir + "px";
      menuGrab.current.style.width = maxDir + "px";
    };
    
    const handleDrag = (type) => {
        moved = false;
        
        initMousePos = "unmoved";
        
        if (type === "m") {
            //mouse
            menuGrab.current.classList.toggle("active");
            setMenu(true);
        }
        else {
            document.body.classList.add("disable");
            document.body.style.overflow = "hidden";
            document.body.style.overscrollBehavior = "none";
            document.addEventListener("touchmove", onMouseMove);
            document.addEventListener("touchend", releaseDrag);
        }
    }
    
    const releaseDrag = _=> {
        document.removeEventListener("mousemove", onMouseMove);
        document.removeEventListener("mouseup", releaseDrag);
        document.removeEventListener("touchmove", onMouseMove);
        document.removeEventListener("touchend", releaseDrag);
        
        document.body.classList.remove("disable");
        document.body.style.overflow = "auto";
        document.body.style.overscrollBehavior = "auto";
        
        if (menuGrab.current.clientWidth < 100) {
            if (initMousePos == "unmoved") {
                menuGrab.current.classList.toggle("active");
                setMenu(true);
            }
            else {
                menuGrab.current.style.removeProperty('width');
                menuGrab.current.style.removeProperty('height');
            }
            
        }
        else {
            menuGrab.current.classList.toggle("active");
            setMenu(true);
        }
    }
    
    const closeMenu =_=> {
        menuGrab.current.style.removeProperty('width');
        menuGrab.current.style.removeProperty('height');
        menuGrab.current.classList.toggle("active");
        document.body.classList.remove("disable");
        document.body.style.overflow = "auto";
        setMenu(false);
    }
    
    return (
        <>
        {
            (menuOpen) && <Menu closeMenu={closeMenu}/>
        }
        <div className="menu" onClick={() => handleDrag("m")} onTouchStart={() => handleDrag("t")} ref={menuGrab}>
            <img src={arrow} alt="arrow"></img>
            <div className="menu-grab"></div>
        </div>
        </>
    )
    
}

export default Footer;