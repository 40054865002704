import React, {useRef} from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";

const Scroller = props => {
    
    let item = {
        visible: {opacity:1,y:0, transition:{ duration:1,ease: "easeInOut"}},
        hidden: {opacity:0,y:30, transition:{ duration:1,ease: "easeInOut"}}
    }
    
    const videoPlayer = useRef(null);
    
    const handlePlayback = _ => {
        if (videoPlayer.current.paused) {
            videoPlayer.current.play();
        }
        else {
            videoPlayer.current.pause();
        }
        
    }
    
    return (
        <>
        <section className="scroller">
            <div className="video">
                <video ref={videoPlayer} onClick={handlePlayback} autoPlay loop playsInline muted controlsList="noremoteplayback">
                    <source src={require(`img/projects/def/${props.work.slug}/scroller/scroller.webm`)} type="video/webm"/>
                    <source src={require(`img/projects/def/${props.work.slug}/scroller/scroller.mp4`)} type="video/mp4"/>
                </video>
            </div>
            <figcaption><p>{props.work.focus.scroller}</p></figcaption>
        </section>
        </>
    )
}

export default Scroller;