import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Text = props => {

  let item = {
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
    hidden: { opacity: 0, y: 30, transition: { duration: 1, ease: "easeInOut" } }
  }

  return (
    <>
      {props.work.focus.text.map((text) => <TextTile key={text} work={text} spaceText={props.spaceText} slug={props.work.slug} />)}
    </>
  )
}

const TextTile = props => {
  return (
    <section className="text">
      <div className="info">
        <h1>{props.work.title}</h1>
        {props.spaceText(props.work.body)}
        {props.work?.link && <a href={props.work.link} target="_blank" rel="noopener"><h3>Listen &#8594;</h3></a>}
      </div>
      {(props.work.img !== null) && (
        <div className="images">
          <div className="photograph-overlap">
            <motion.div className="photograph" style={{ backgroundImage: `url(${require(`img/projects/def/${props.slug}/${props.work.img[0]}`)})` }}>
            </motion.div>
            <motion.div className="photograph-sub" style={{ backgroundImage: `url(${require(`img/projects/def/${props.slug}/${props.work.img[1]}`)})` }}>
            </motion.div>
          </div>
        </div>
      )}
    </section>
  )
}

export default Text;