import React, { useRef, useEffect } from "react";

const Research = props => {

  const [state, setState] = React.useState(0);

  useEffect(() => {
    if (props.work.focus.research.img.length != 1) {
      const timeout = setTimeout(() => {
        if (state == (props.work.focus.research.img.length - 1)) {
          setState(0);
          console.log("reset");
        }
        else {
          setState(state => state + 1);
          console.log("up it");
        }
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [state]);

  return (
    <>
      <section className="research">
        <div className="info">
          <h1>Research</h1>
          {props.spaceText(props.work.focus.research.content)}
        </div>
        <div className="images">
          <div style={{ backgroundImage: `url(${require(`img/projects/def/${props.work.slug}/focus/${props.work.focus.research.img[state]}`)})` }}>
          </div>
        </div>
      </section>
    </>
  )
}

export default Research;