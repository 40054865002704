import React from "react";
import { motion, useAnimation } from "framer-motion";
import {Link} from "react-router-dom";
import MenuGrab from "components/MenuGrab";

import logoSm from "img/logo-sm.svg";
import me from "img/me2.jpg";
import closeIcon from "img/icons/close.svg";
import insta from "img/icons/social/instagram.svg";
import github from "img/icons/social/github.svg";

const Menu = props => {
    
    let item = {
        visible: {opacity:1,y:0, transition:{ duration:1,ease: "easeInOut"}},
        hidden: {opacity:0,y:30, transition:{ duration:1,ease: "easeInOut"}}
    }
    const animOut = useAnimation();
    const closeMenu =_=> {
        animOut.start({
            opacity: 0,
            transition:{ duration:0.5,ease: "easeInOut"}
        })
    }
    
    return (
        <>
        <motion.div className="main-menu" initial="visible" animate={animOut} onAnimationComplete={props.closeMenu}>
            <motion.div initial="hidden" animate="visible" className="inner-menu">
                <motion.div variants={item} className="header">
                    <img src={logoSm} alt="deroche" className="logo"></img>
                    <img src={closeIcon} alt="close icon" className="close-icon" onClick={closeMenu}></img>
                </motion.div>
                <motion.p variants={item} className="underline">Toronto based interaction designer, JS & React interface developer, and music enthusiast.</motion.p>
                <motion.nav variants={item}>
                    <div>
                        <Link onClick={props.closeMenu} to="/work"><h1 className="thin">work</h1></Link>
                        <Link onClick={props.closeMenu} to="/me"><h1 className="thin">me</h1></Link>
                    </div>
                    <img src={me} alt="another of me"></img>
                </motion.nav>
                <motion.div variants={item} className="foot">
                    <h4>Talk to me</h4>
                    <section><a href="mailto:aaron@deroche.design"><p>aaron@deroche.design</p></a></section>
                    <section className="social-icons">
                        <a href="https://instagram.com/aaronderoche" target="_blank" rel="noopener"><img src={insta}></img></a>
                        <a href="https://github.com/derochedesign" target="_blank" rel="noopener"><img src={github}></img></a>
                    </section>
                </motion.div>
            </motion.div>
        </motion.div>
        </>
    )
}

export default Menu;