import React from "react";

const Outcomes = props => {
  return (
    <>
      <section className="outcomes">
        <div className="info">
          <h1>Outcomes</h1>
          {props.spaceText(props.work.focus.outcomes)}
          {props.work.focus?.outcomesLink && props.work.focus.outcomesLink.map(linkData =>
            <p><a href={linkData.link} target={"_blank"} rel="noopener">
              {linkData.title}
            </a></p>
          )}
        </div>
      </section>
    </>
  )
}

export default Outcomes;