import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import { Swipeable } from 'react-swipeable';
import "scroll-behavior-polyfill";
import ProjectTile from "components/ProjectTile";

import arrow from "img/icons/arrow.svg";

const ProjectSlider = props => {

  const [currentWork, setCurrentWork] = useState(props.proPos);
  //(props.appState === null) ? (1) : props.appState
  let orderWorks = props.works.sort((a, b) => (a.order > b.order) ? 1 : -1);

  const history = useHistory();
  const workInner = useRef(null);

  useEffect(() => {
    setCurrentWork((currentWork === null) ? (1) : currentWork);
  });

  const handlePrev = () => {
    if (currentWork > 1) {
      setCurrentWork(currentWork - 1);
    }
  }
  const handleNext = () => {
    if (currentWork < props.works.length) {
      setCurrentWork(currentWork + 1);
    }
  }
  const navFocus = (slug) => {
    props.workRef.current.scrollIntoView({ behavior: "smooth" });
    props.workRef.current.classList.add("expand");
    props.setProPos(currentWork);
    setTimeout(_ => {
      history.push(`/${slug}`);
    }, 1000);

  }

  let item;

  if (props.appState === null) {
    item = {
      visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeInOut" } },
      hidden: { opacity: 0, y: 30, transition: { duration: 1, ease: "easeInOut" } }
    }
  }
  else {
    item = {
      visible: { opacity: 1, y: 0, transition: { duration: 0, ease: "easeInOut" } },
      hidden: { opacity: 1, y: 0, transition: { duration: 0, ease: "easeInOut" } }
    }
  }

  const config = {
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
    delta: 50
  };

  return (
    <motion.section variants={item} className="projects">
      <Swipeable {...config} className="projects-row-container">
        <section initial="hidden" animate="visible" className="projects-row" ref={workInner}>
          {
            orderWorks.map((work, i) =>
              <ProjectTile key={i} i={i} workLength={props.works.length} workRef={props.workRef} data={work} workPos={currentWork} vW={props.vW} navFocus={navFocus} vH={props.vH} />
            )
          }

        </section>
        <div className="project-head">
          <nav className="project-nav">
            <button className="icon round" onClick={handlePrev} aria-disabled={!(currentWork > 1)}>
              <img 
                className={!(currentWork > 1) ? "disabled" : ""} 
                src={arrow} 
                alt="left arrow"
              />
            </button>
            
            <button className="icon round" onClick={handleNext} aria-disabled={!(currentWork < props.works.length)}>
              <img 
                className={!(currentWork < props.works.length) ? "disabled" : ""} 
                src={arrow} 
                alt="right arrow"
              />
            </button>
          </nav>
        </div>
      </Swipeable>
    </motion.section>
  )
}

export default ProjectSlider;