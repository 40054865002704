import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import "scroll-behavior-polyfill";
import ProjectSlider from "components/ProjectSlider";
import Footer from "components/Footer";
import Topbar from "components/Topbar";
import Focus from "components/Focus";

import arrow from "img/icons/arrow.svg";

const Main = props => {

  let item;
  const workArea = useRef(null);
  const [projectPos, setProjectPos] = useState(1);
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const mainElem = useRef(null);
  const focusElem = useRef(null);

  const fabAnim = {
    visible: { opacity: 1, y: 0, transition: { duration: 2, ease: "easeInOut" } },
    hidden: { opacity: 1, y: 100, transition: { duration: 2, ease: "easeInOut" } }
  }

  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    console.log(locationKeys);
    if (props.position === "work") {
      if (history.action === "POP") {
        workArea.current.scrollIntoView();
      }
      else {
        workArea.current.scrollIntoView({ behavior: "smooth" });
      }
    }

    return history.listen((location, e) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[0] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          //forward event
        }
        else {
          setLocationKeys((keys) => [location.key, ...keys]);
          //back event
          workArea.current.classList.remove("expand");
        }
      }
    });

  }, [locationKeys,]);

  const closeFocus = () => {
    workArea.current.classList.remove("expand");
  }

  const navListener = () => {
    closeFocus();
  }

  const handleContact = () => {
    if (menuState) {
      setMenuState(false);
    }
    else {
      setMenuState(true);
    }
  }

  return (
    <>
      {
        ((props.position === "focus")) &&
        <Focus url={props.match.params.project} works={props.works} vH={props.vH} closeFunc={closeFocus} />
      }

      <Topbar state={(props.position === "work") ? "work" : null} navListener={navListener} mainElem={mainElem} focusElem={focusElem} />
      <motion.main ref={mainElem} initial="hidden" animate="visible" className={((props.position === "focus") ? ("disable") : "")}>
        {/* <section className="tile intro" style={{backgroundImage:`url(${testArt})`}}>
                    </section> */}
        <section className="tile intro">
          {/* <img className="logo" src={logo}></img> */}
          <div className="shape-break">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="hero">
            <div className="hero-type">
              <div className="hero-text">
                <h1>User experience designer & developer, with a specific interest in musical applications.</h1>
              </div>
              <div className="hero-sub">
                <p>Explore a collection of my work.</p>
                <Link to="/work"><img className="arrow" src={arrow} alt="go down the page" /></Link>
              </div>
            </div>
            {/* <img className="hero-img" src={testArt}></img> */}
          </div>
        </section>

        <section ref={workArea} className="tile projects">

          <ProjectSlider
            works={props.works.filter(work => work.def === true)}
            vW={props.vW}
            vH={props.vH}
            proPos={projectPos}
            setProPos={setProjectPos}
            workRef={workArea}
          />

        </section>
        <Footer />
      </motion.main>
    </>
  )
}

export default Main;