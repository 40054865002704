import React, {useState, useEffect, useRef} from "react";
import { motion, useAnimation } from "framer-motion";

const ProjectTile = props => {
    
    const focusAnim = useAnimation();
    const widthMultiplier = 1;
    
    //let tempAction = props.histAction;
    let tempAction = "";
    
    useEffect( _ => {
        //tempAction = "";
    });
    
    return (
        <>
        
        <motion.div 
        initial={(props.workPos === 1) ? {x:(props.workPos - 1) * ((props.vW * widthMultiplier)) * -1} : {x:(props.workPos - 1) * ((props.vW * widthMultiplier)) * -1}} 
        animate={{x:(props.workPos - 1) * ((props.vW * widthMultiplier)) * -1}}
        transition={(tempAction !== "POP") ? {duration:0.3,ease: "easeInOut"} : {duration:0,ease: "easeInOut"}} 
        className={(props.workPos === (props.i+1)) ? ("project-tile") : ("project-tile off")}>
            
            <motion.div onClick={_=> props.navFocus(props.data.slug)} className="project-images">
                <motion.div className="project-image" style={{backgroundImage: `url(${require(`img/projects/def/${props.data.slug}/thumb.jpg`)})`}}>
                </motion.div>
                <motion.div className="project-image-sub" style={{backgroundImage: `url(${require(`img/projects/def/${props.data.slug}/thumb2.jpg`)})`}}>
                </motion.div>
                <motion.div className="project-image-sub2" style={{backgroundImage: `url(${require(`img/projects/def/${props.data.slug}/thumb3.jpg`)})`}}>
                </motion.div>
            </motion.div>
            <motion.div className="project-info">
                <aside className="project-meta">
                    <div className="tags">
                        {
                            props.data.tags.map((tag,i) => <h4 key={i}>{tag}</h4>)
                        }
                    </div>
                    <h1 onClick={_=> props.navFocus(props.data.slug)}>{props.data.title}</h1>
                    <h3 className="date">{`(${props.data.year})`}</h3>
                </aside>
                <div className="extra-info">
                    <h4>{props.i + 1}/{props.workLength} | {props.data.status}</h4>
                </div>
                <p className="desc">{props.data.desc}</p>
                <div className="expand-project">
                    <h3 onClick={_=> props.navFocus(props.data.slug)}>{(props.data.slug === "flower") ? "Coming Soon" : `View Project ${String.fromCharCode(8594)}`}</h3>
                    
                </div>
            </motion.div>
        </motion.div>
        
        {/* <motion.div className="focus-anim-in" initial={{opacity:0,scale:0.7, transition:{ duration:0.6,ease: "easeInOut"}}} animate={focusAnim} style={{backgroundColor:props.data.colour}} onAnimationComplete={_=> props.navFocus(props.data.slug)}></motion.div> */}
        </>
    )
}

export default ProjectTile;