import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";

const Challenges = props => {
    
    let item = {
        visible: {opacity:1,y:0, transition:{ duration:1,ease: "easeInOut"}},
        hidden: {opacity:0,y:30, transition:{ duration:1,ease: "easeInOut"}}
    }
    
    return (
        <>
        <section className="challenges">
            <h1>Challenges</h1>
            <div className="info">
                
                {props.work.focus.challenges.content.map((chal, i) => <p key={i}>{chal}</p>)}
                
            </div>
        </section>
        </>
    )
}

export default Challenges;