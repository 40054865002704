import React from "react";

const Gallery = props => {
  return (
    <>
      <section className={`gallery ${props.work.focus?.gallerySpaced ? "spaced" : ""}`}>
        <div className={`inner-gallery`}>
          {props.work.focus.gallery.map((ima, i) => <div key={i}><img src={require(`img/projects/def/${props.work.slug}/focus/${ima}`)}></img></div>)}
        </div>
      </section>
    </>
  )
}

export default Gallery;